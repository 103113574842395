<template>
	<div id="newsDetail" class="newsDetail" ref="newsDetail">
		<!-- 头部 nav导航 -->
		<header-nav :tab="true"></header-nav>
		<!-- 关于我们-->
		<div class="content-box">
			<!-- <div class="news-logo">
				<img src="/images/news-bg.png" style="width: 100%;height: 100%;"/>
			</div> -->
			<div class="news-logo-top">
				<div class="t2">{{detailInfo.title}}</div>
				<div class="c2" v-if="detailInfo.author">{{detailInfo.author}} | {{detailInfo.createTime}}</div>
				<div class="c2" v-else>{{detailInfo.createTime}}</div>
				<!-- <div class="news-img"> -->
					<img :src="detailInfo.thumbnail" alt :style="is_vertical?'width: 100%;':''"/>
				<!-- </div> -->
				<div class="t3" v-html="detailInfo.content"></div>
			</div>
		</div>
		
		<!-- 底部链接 -->
		<!-- 友情链接 -->
		<section class="friend uni-flex-column uni-flex-center" style="margin-top: 30px;" v-if="!is_vertical">
			<div class="friend-content">
				<div class="friend-text">
					<span>友情链接：</span>
					<a href="https://shop117115684.taobao.com/?spm=a2142.8996326.sellercard.15.146f2be3VrqbMR">千雪制冷淘宝店</a>
				</div>
			</div>
		</section>
		<!-- 联系我们 -->
		<!-- 小屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-if="is_vertical">
			<div class="foot-content">
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="foot-scan">微信扫一扫</div>
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
			</div>
		</section>
		<!-- 大屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-else>
			<div class="foot-content">
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import api from '@/utils/api.js';
	import headerNav from '@/components/header.vue';
	export default {
		name: 'newsDetail', // 新闻详情
		components: {
			headerNav,
		},
		data() {
			return {
				is_vertical: false,  //false 小屏  true 大屏
				win_width: document.body.clientWidth,
				id: 0,
				detailInfo: {},
				img: '/images/swiper-2.png',
				title: '上半年券商持仓路线曝光 赠持制造业中小创领域',
				summary: '拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得拉开距离打卡机拉开距离看见了会计处理呢了解了看见了看 见你吗 看空间来看看觉得',
				time: '2020-10-20'
				
			}
		},
		computed: {
			
		},
		created() {
			
		},
		mounted() {
			/* 获取页面高宽 */
			this.body_width = document.getElementById('newsDetail').offsetWidth;
			this.body_height = document.getElementById('newsDetail').clientHeight;
			const that = this;
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.win_width = window.screenWidth;
				})();
			};
			console.log(this.win_width);
			if (this.win_width < 768) {
				//小屏
				this.is_vertical = true;
			} else if (this.win_width >= 768) {
				//大屏
				this.is_vertical = false;
			}
			
			this.id = this.$route.query.id;
			this.api_newsDetail(this.id);
		},
		watch: {
		},
		methods: {
			api_newsDetail(id) {
				api.newsDetail(id).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.detailInfo = res.data.article;
					}
				})
			},
		}
	}
</script>

<style scoped="" lang="less">
	.yx-h5 {
		font-size: 14px;
	}

	.newsDetail {
		height: 100%;
		overflow: auto;
		font-size: 20px;
		color: #bdbdbd;
		background-color: #F2F2F2;
	}
	
	.content-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.news-logo {
			width: 100%;
			height: 900px;
		}
		
		.news-logo-top {
			width: 1200px;
			margin: 50px 15px;
			text-align: left;
			background-color: #FFFFFF;
			padding: 30px;
			.news-img {
				margin-top: 30px;
				width: 100%;
				height: 500px;
			}
			.t2 {
				margin-top: 20px;
				font-size: 28px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
				line-height: 30px;
			}
			.t3 {
				margin: 20px 0;
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
				line-height: 30px;
			}
			.c2 {
				margin-top: 20px;
				color: #999999;
				font-size: 20px;
				font-family: Montserrat;
				font-weight: bold;
				line-height: 30px;
				padding: 20px 0;
				border-bottom: 1px solid #F2F2F2;
			}
			
		}
	}

	.friend {
		margin-top: 30px;
		height: 50px;
		background-color: #F5F5F5;
		
		.friend-content {
			display: flex;
			flex-direction: wrap;
			width: 1200px;
			
			.friend-text {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 24px;
			}
		}
	}
	
	.foot {
		height: 200px;
		background-color: #0E82D9;
		
		.foot-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			
			.foot-text {
				display: flex;
				flex-direction: column;
				text-align: left;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				.span {
				}
			}
			
			.foot-logo {
				width: 142px;
				height: 142px;
			}
		}
	}

	/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		
		.newsDetail {
			overflow-y: auto;
			overflow-x: hidden;
		}
		
		.content-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			.news-logo {
				width: 100%;
				height: 300px;
			}
			
			.news-logo-top {
				width: 90%;
				margin: 30px 15px;
				text-align: left;
				background-color: #FFFFFF;
				.news-img {
					margin-top: 30px;
					width: 100%;
					height: 200px;
				}
				.t2 {
					margin-top: 10px;
					font-size: 20px;
					line-height: 30px;
				}
				.t3 {
					margin: 20px 0;
					font-size: 16px;
					line-height: 30px;
				}
				.c2 {
					margin-top: 10px;
					color: #999999;
					font-size: 16px;
					padding: 10px 0;
				}
				
			}
		}
			
		.foot {
			// margin-top: 30px;
			height: 370px;
			background-color: #0E82D9;
			
			.foot-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				.foot-scan {
					padding: 20px 0;
					text-align: center;
					font-size: 12px;
					color: #FFFFFF;
				}
				.foot-text {
					margin-top: 20px;
					text-align: center;
					font-size: 12px;
				}
				
				.foot-logo {
					width: 120px;
					height: 120px;
				}
			}
		}

		.header .content .nav {
			display: none !important;
		}

		.content {
			justify-content: space-between !important;
			padding: 0 20px;

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}

			.langs {
				display: none;
			}
		}
	}
</style>
